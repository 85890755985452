import React from "react"
import { Auth0Provider } from '@auth0/auth0-react'
import './src/styles/global.css'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENTID}
    cacheLocation={'localstorage'}
    authorizationParams={{
      redirect_uri: window.location.origin+'/profile',
      audience: process.env.GATSBY_AUTH0_AUDIENCE,
    }}
    >
      {element}
    </Auth0Provider>
}