exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-find-support-resistance-js": () => import("./../../../src/pages/find-support-resistance.js" /* webpackChunkName: "component---src-pages-find-support-resistance-js" */),
  "component---src-pages-identify-reversals-js": () => import("./../../../src/pages/identify-reversals.js" /* webpackChunkName: "component---src-pages-identify-reversals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboard-js": () => import("./../../../src/pages/leaderboard.js" /* webpackChunkName: "component---src-pages-leaderboard-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pattern-breakouts-js": () => import("./../../../src/pages/pattern-breakouts.js" /* webpackChunkName: "component---src-pages-pattern-breakouts-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

