module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-fix-fouc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8ZWQT3CL0Z"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"image":"/favicon.ico","description":"Practice daytrading skills atomically. Individually improve one skill at a time.","noindex":false,"dangerouslySetAllPagesToNoIndex":false,"language":"en","openGraph":{"type":"website","locale":"en_US","url":"https://freecharts.org/","site_name":"Free Charts"},"twitter":{"handle":"@eternalnooblet","site":"@site","cardType":"summary_large_image"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
